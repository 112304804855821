import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPickerModalContentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserPickerModalContentQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string }> };


export const UserPickerModalContentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserPickerModalContent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUserPickerModalContentQuery__
 *
 * To run a query within a React component, call `useUserPickerModalContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPickerModalContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPickerModalContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPickerModalContentQuery(baseOptions?: Apollo.QueryHookOptions<UserPickerModalContentQuery, UserPickerModalContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPickerModalContentQuery, UserPickerModalContentQueryVariables>(UserPickerModalContentDocument, options);
      }
export function useUserPickerModalContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPickerModalContentQuery, UserPickerModalContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPickerModalContentQuery, UserPickerModalContentQueryVariables>(UserPickerModalContentDocument, options);
        }
export function useUserPickerModalContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserPickerModalContentQuery, UserPickerModalContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserPickerModalContentQuery, UserPickerModalContentQueryVariables>(UserPickerModalContentDocument, options);
        }
export type UserPickerModalContentQueryHookResult = ReturnType<typeof useUserPickerModalContentQuery>;
export type UserPickerModalContentLazyQueryHookResult = ReturnType<typeof useUserPickerModalContentLazyQuery>;
export type UserPickerModalContentSuspenseQueryHookResult = ReturnType<typeof useUserPickerModalContentSuspenseQuery>;
export type UserPickerModalContentQueryResult = Apollo.QueryResult<UserPickerModalContentQuery, UserPickerModalContentQueryVariables>;