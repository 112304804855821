import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { gql } from '@apollo/client';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useModalContext,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useUserPickerModalContentSuspenseQuery } from './UserPickerModal.generated';

type Props = {
  onSelect: (userId: string) => void;
};

gql`
  query UserPickerModalContent {
    users {
      id
      name
    }
  }
`;

const UserPickerModalContent = ({ onSelect }: Props) => {
  const modalContext = useModalContext();
  const {
    data: { users },
  } = useUserPickerModalContentSuspenseQuery();

  return (
    <>
      <ModalHeader>ユーザーを選択</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <TableContainer overflowY='scroll' maxH='60vh'>
          <Table variant='simple' size='sm'>
            <Thead>
              <Tr>
                <Th>ユーザー名</Th>
                <Th w={0}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.name}</Td>
                  <Td>
                    <Button
                      onClick={() => {
                        onSelect(user.id);
                        modalContext.onClose();
                      }}
                    >
                      選択
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </ModalBody>

      <ModalFooter>
        <Button colorScheme='gray' mr={3} onClick={modalContext.onClose}>
          閉じる
        </Button>
      </ModalFooter>
    </>
  );
};

export const useUserPickerModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentProps, setContentProps] = useState<Props | null>(null);

  return {
    element: (
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <SuspenseWithSpinner>
            {contentProps && <UserPickerModalContent {...contentProps} />}
          </SuspenseWithSpinner>
        </ModalContent>
      </Modal>
    ),
    open: (props: Props) => {
      setContentProps(props);
      onOpen();
    },
  };
};
