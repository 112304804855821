import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';

import { IUser, POSITIONS } from '@/modules/users';
import { useToast } from '@/utils/atoms/toast';
import { USERS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { cleanPhoneNumber, formatPhoneNumber } from '@/utils/phoneNumber';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export type UserFormData = {
  name: string;
  password?: string;
  email: string;
  phoneNumber?: string;
  oldPosition?: string;
  position?: string;
};

type UserFormProps = {
  user?: IUser;
  cancelUserAction: () => void;
  createUser: (payload: UserFormData) => void;
  updateUser: (payload: UserFormData) => void;
};

const PASSWORD_LENGTH = 16;
const JAPANESE_COUNTRY_CODE = '+81';

const defaultValues: UserFormData = {
  name: '',
  email: '',
  position: POSITIONS[1].value,
};

const UserForm: FC<UserFormProps> = (props: UserFormProps) => {
  const { user, cancelUserAction, createUser, updateUser } = props;

  const { toast } = useToast();
  const { t, t_errors, t_toasts, t_ns } = useTranslation(USERS);

  const isEditing = !!user;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isPasswordDisabled, setIsPasswordDisabled] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const handleShowClick = () => setShowPassword(!showPassword);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting, errors },
    getValues,
  } = useForm<UserFormData>({
    defaultValues,
  });

  const onPhoneNumberChange = useCallback((phoneNumber?: string) => {
    if (!phoneNumber) {
      setPhoneNumber('');
      return;
    }

    const targetValue = formatPhoneNumber(phoneNumber);
    setPhoneNumber(targetValue);
  }, []);

  useEffect(() => {
    if (user) {
      const { name, email, phoneNumber, officeRoles } = user;
      setValue('name', name);
      setValue('email', email);
      onPhoneNumberChange(cleanPhoneNumber(phoneNumber));
      if (officeRoles && officeRoles.length > 0) {
        const position = officeRoles[0].role.position;
        setValue('position', position || '');
      }
      setIsPasswordDisabled(true);
    }
  }, [onPhoneNumberChange, setValue, user]);

  const onSubmit = (values: UserFormData) => {
    const payload = values;

    if (phoneNumber) {
      const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);

      const maxLength = 11; // for Firebase validation E.164 format

      if (cleanedPhoneNumber.length === maxLength) {
        const inputPhoneNumber = `${JAPANESE_COUNTRY_CODE} ${phoneNumber}`;
        payload.phoneNumber = inputPhoneNumber;
      } else {
        toast({
          title: t_errors('phone-format-incorrect'),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return;
      }
    }

    if (user) {
      const { officeRoles } = user;
      if (officeRoles && officeRoles.length > 0) {
        const oldPosition = officeRoles[0].role.position;
        payload.oldPosition = oldPosition;
      }
    }

    isEditing ? updateUser(payload) : createUser(payload);
  };

  const resetPassword = () => {
    setIsPasswordDisabled(false);
    setValue('password', '');
  };

  const generatePassword = () => {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numericChars = '0123456789';
    const specialChars = '!@#$%^&*()_-+=';
    const charset = lowercaseChars + uppercaseChars + numericChars + specialChars;

    let password = '';
    for (let i = 0; i < PASSWORD_LENGTH; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    setValue('password', password);
  };

  const copyToClipboard = () => {
    const { clipboard } = navigator;
    if (!clipboard) {
      return;
    }
    const { password = '' } = getValues();
    clipboard
      .writeText(password)
      .then(() => {
        toast({
          title: t_toasts('success.users.password-copied'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: t_errors('failed.users.failed-to-copy-password'),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Box bg='neutral.50'>
      <VStack align='stretch'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box pb={{ base: 16, md: 0 }}>
            <FormControl isInvalid={!!errors.name} px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>
                {t('form.username')}
                <Text as='span' color='error.500'>
                  *
                </Text>
              </FormLabel>
              <Input
                {...register('name', {
                  required: t('warning.please-enter-username'),
                })}
                autoComplete='off'
              />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.email} px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>
                {t('form.email')}
                <Text as='span' color='error.500'>
                  *
                </Text>
              </FormLabel>

              <Input
                {...register('email', {
                  required: t('warning.please-enter-email'),
                  pattern: {
                    value: /\S+@\S+\.\S+/, // RFC対応していないので、正規表現を見直す必要あり
                    message: t_errors('email-format-incorrect'),
                  },
                })}
                autoComplete='off'
              />
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>
            <FormControl px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>{t('form.mobile-phone')}</FormLabel>
              <Input
                maxLength={13}
                minLength={10}
                value={phoneNumber}
                onChange={(e) => onPhoneNumberChange(e.target.value)}
                autoComplete='off'
              />
            </FormControl>
            <FormControl px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>{t_ns('user-role')}</FormLabel>
              <Select {...register('position')}>
                {POSITIONS.map((position) => (
                  <option key={position.value} value={position.value}>
                    {t(`users.roles.${position.label}`)}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl isInvalid={!!errors.password} px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>
                {t('form.password')}
                <Text as='span' color='error.500'>
                  *
                </Text>
                {isEditing && (
                  <Button
                    variant='ghost'
                    color='primary.400'
                    size='sm'
                    fontWeight='normal'
                    fontSize='md'
                    m={0}
                    px={1}
                    onClick={() => resetPassword()}
                  >
                    ({t('actions.reset')})
                  </Button>
                )}
              </FormLabel>
              <Flex
                justifyItems='center'
                alignItems='center'
                direction={{ base: 'column', md: 'row' }}
                gap={3}
              >
                <InputGroup w='full'>
                  <Input
                    disabled={isPasswordDisabled}
                    autoComplete='new-password'
                    type={showPassword ? 'text' : 'password'}
                    {...register('password', {
                      required: {
                        value: !isPasswordDisabled,
                        message: t('warning.please-enter-password'),
                      },
                      minLength: {
                        value: 6,
                        message: t_errors('password-min-length', { length: 6 }),
                      },
                    })}
                  />

                  <InputRightElement mr={5}>
                    {!isPasswordDisabled && (
                      <IconButton
                        aria-label='Copy to clipboard'
                        size='sm'
                        variant='ghost'
                        color='neutral.500'
                        icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                        onClick={handleShowClick}
                      />
                    )}
                    <IconButton
                      aria-label='Copy to clipboard'
                      size='sm'
                      variant='ghost'
                      color='neutral.500'
                      onClick={() => copyToClipboard()}
                      icon={<MdContentCopy />}
                    />
                  </InputRightElement>
                </InputGroup>
                {!isPasswordDisabled && (
                  <Button
                    ml='auto'
                    size='sm'
                    variant='outline'
                    colorScheme='primary'
                    onClick={() => generatePassword()}
                  >
                    {t('actions.generate')}
                  </Button>
                )}
              </Flex>
              <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
              {!isEditing && <FormHelperText>{t_ns('sign-up-helper')}</FormHelperText>}
            </FormControl>
          </Box>
          <Flex
            justifyContent='flex-end'
            position={{ base: 'fixed', md: 'unset' }}
            width='full'
            bottom={0}
            left={0}
            p={{ base: 4, md: 2 }}
            backgroundColor={{ base: 'neutral.0', md: 'unset' }}
          >
            <Button
              variant='outline'
              colorScheme='primary'
              width={{ base: '50%', md: 'auto' }}
              onClick={() => cancelUserAction()}
            >
              {t('actions.cancel')}
            </Button>

            <Button
              ml='2'
              colorScheme='primary'
              isLoading={isSubmitting}
              type='submit'
              width={{ base: '50%', md: '100px' }}
            >
              {isEditing ? t('actions.save') : t('actions.register')}
            </Button>
          </Flex>
        </form>
      </VStack>
    </Box>
  );
};
export default UserForm;
